const CONTRACTS = {
  250: {
    // fantom
    Vault: "0x0Fb84ADB9c16D28b92c09c382CaadbDdD8e354c4",
    Router: "0x9E4fB3C60a08a0685d32C2A7788843C440171060",
    VaultReader: "0x8e2a632a3B76E7C3C10e5CFd82af87A6e6Dfd06f",
    Reader: "0x05733de76Ba7EF6CAC81507f884dC839fB905aC9",
    EquityManager: "0x9ca9805dBAcE7AeE0565c546145CA105B202a979",
    RewardRouter: "0x59f557679fa642e0E37CbADDB64d39403D05dB35",
    RewardReader: "0x03F7ad0A6546a367A78cfd65159a86cE9E029EC5",
    EQUITY: "0x0d90b147fB412fB8469B82bc73623FF8e19FCCf0",
    EQUAL: "0x3Fd3A0c85B70754eFc07aC9Ac0cbBDCe664865A6", // done
    ES_EQUAL: "0xDFedB4cEa56a4FB74BC4811Be3c47AD7eb011B5f",
    BN_EQUAL: "0xDf3B12825c2aD499C21E36eFc28803a82Fd73e58",
    EquityToken: "0xc4a987A83AcfACa50e19a20bab263cafD5e7506c",
    EquityFarm: "0xe17E46780461bB7E7397fB36aC6d03A307055c4e",
    USDE: "0x2AE97f33b77C17D10810Ee903c2dd288Ad362f61",
    StakedEqualTracker: "0x8682e4d5D7Fd68E152a0F13f6A8f45BD004babeB",
    BonusEqualTracker: "0x3A093954508Fc0f63eF4dd423c6C33F88F248873",
    FeeEqualTracker: "0x5fEbB20a3674A5d4Ac8CC2e45d1cda8a8f90F508",
    StakedEquityTracker: "0xac941d23365A2F7278a4fd41041E5C85373AD47e",
    FeeEquityTracker: "0x0454a90a6E0E525E273A9e3173e1aE2aFD9EEDc6",
    StakedEqualDistributor: "0x98b626b0383EeB25476e1A003209de44902666f3",
    StakedEquityDistributor: "0x480C496DfDb78cFB61913649343398edef67BE9d",
    EqualVester: "0xe96283B220AA05d1713003C73250D12f5a9Eb519",
    EquityVester: "0xbF85dACFE1Eb2B0D0A365AEf3F23c9d1b39f1324",
    OrderBook: "0xc75587C321DE27dFA1767D33908e623c2C83B7d2",
    PositionManager: "0x940407988c4B94FE95EE4e0a91983015f765B2B3",
    OrderBookReader: "0x162378c7C6DF312285B6F1D1B27D07CBa30C9113",
    PositionRouter: "0x6690ADb6445971B04524abE951a18d230e863ECa",
    ReferralStorage: "0x90Dc104E03BFBD7AD264b067AcCE427350da0E2b",
    ReferralReader: "0xD0f8D544D2534e09937b6D8aB1C2734530a1ED8B",
    UniswapEqualUsdcPool: "0xe21aA505CFC43AfA4AA2B6cAcd64afd51Be50904",
    UniswapEqualUsdcPoolUsdcToken: "0x28a92dde19D9989F39A49905d7C9C2FAc7799bDf",
    UniswapEqualWftmPool: "0x3d6c56f6855b7Cc746fb80848755B0a9c3770122",
    NATIVE_TOKEN: "0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83",
  },
};

const tryRequire = (path) => {
  try {
    return require(`${path}`);
  } catch (err) {
    return undefined;
  }
};
const devAddresses = tryRequire("./development.Addresses.js");

export function getContract(chainId, name) {
  const contracts = process.env.NODE_ENV === "development" && devAddresses ? devAddresses.CONTRACTS : CONTRACTS;

  if (!contracts[chainId]) {
    throw new Error(`Unknown chainId ${chainId}`);
  }
  if (!contracts[chainId][name]) {
    throw new Error(`Unknown constant "${name}" for chainId ${chainId}`);
  }
  return contracts[chainId][name];
}

export const XGMT_EXCLUDED_ACCOUNTS = [
  "0x330eef6b9b1ea6edd620c825c9919dc8b611d5d5",
  "0xd9b1c23411adbb984b1c4be515fafc47a12898b2",
  "0xa633158288520807f91ccc98aa58e0ea43acb400",
  "0xffd0a93b4362052a336a7b22494f1b77018dd34b",
];

export const EQUAL_MULTISIG_ADDRESS = "0xC424C343554aFd6CD270887D4232765850f5e93F";
export const MVD_TREASURY_ADDRESS = "0xC424C343554aFd6CD270887D4232765850f5e93F";
