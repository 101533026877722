import React, { useState, useEffect } from "react";
import './WarningBanner.css';

const WarningBanner = ({ children }) => {
  const [daysLeft, setDaysLeft] = useState(2);

  useEffect(() => {
    // Set up a timer that runs once per day
    const timer = setInterval(() => {
      setDaysLeft((days) => days - 1);
    }, 86400000); // 86400000 milliseconds = 1 day

    // Clear the interval on unmount
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (daysLeft < 0) {
      // Stop the countdown and change the message
      setDaysLeft("Very Soon");
    }
  }, [daysLeft]);

  return (
    <></>
  );
};

export default WarningBanner;
