import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";
import useSWR from "swr";
import { PieChart, Pie, Cell, Tooltip } from "recharts";
import TooltipComponent from "../../components/Tooltip/Tooltip";
import statsBigIcon from "../../img/ic_stats_big.svg";
import tokensBigIcon from "../../img/ic_tokens.svg";
import hexToRgba from "hex-to-rgba";
import { BigNumber, ethers } from "ethers";

import { getWhitelistedTokens, getTokenBySymbol } from "../../data/Tokens";
import { getFeeHistory } from "../../data/Fees";

import {
  fetcher,
  formatAmount,
  formatKeyAmount,
  expandDecimals,
  bigNumberify,
  numberWithCommas,
  formatDate,
  getServerUrl,
  getChainName,
  useChainId,
  USD_DECIMALS,
  EQUALEQUITY_DISPLAY_DECIMALS,
  EQUAL_DECIMALS,
  EQUITY_DECIMALS,
  BASIS_POINTS_DIVISOR,
  FTM,
  getTotalVolumeSum,
  EQUITYPOOLCOLORS,
  getPageTitle,
} from "../../Helpers";
import {
  useTotalEqualInLiquidity,
  useEqualPrice,
  useTotalEqualStaked,
  useTotalEqualSupply,
  useInfoTokens,
  useMvdEqualTreasuryHoldings,
  useMvdEquityTreasuryHoldings,
  useEqualMultisigHoldings,
  useProtocolOwnLiquidity,
  useTotalEqualExcludedFromSupply,
  useTotalVEEqualSupply,
} from "../../Api";
import { getContract } from "../../Addresses";

import Vault from "../../abis/Vault.json";
import Reader from "../../abis/Reader.json";
import EquityManager from "../../abis/EquityManager.json";
import Footer from "../../Footer";

import "./DashboardV2.css";

import equal40Icon from "../../img/logo-equal.png";
import equity40Icon from "../../img/logo-equity.png";

import fantom16Icon from "../../img/logo-fantom.png";
import fantom24Icon from "../../img/logo-fantom.png";

import AssetDropdown from "./AssetDropdown";
import SEO from "../../components/Common/SEO";

import { useTotalVolume, useHourlyVolume, useTotalFees, usePositionStats } from "../../Api";

const { AddressZero } = ethers.constants;

function getVolumeInfo(hourlyVolume) {
  if (!hourlyVolume || hourlyVolume.length === 0) {
    return {};
  }

  const secondsPerHour = 60 * 60;
  const minTime = parseInt(Date.now() / 1000 / secondsPerHour) * secondsPerHour - 24 * secondsPerHour;

  const info = {};
  let totalVolume = bigNumberify(0);
  for (let i = 0; i < hourlyVolume.length; i++) {
    const item = hourlyVolume[i].data;
    if (parseInt(item.timestamp) < minTime) {
      break;
    }

    if (!info[item.token]) {
      info[item.token] = bigNumberify(0);
    }

    info[item.token] = info[item.token].add(item.volume);
    totalVolume = totalVolume.add(item.volume);
  }

  info.totalVolume = totalVolume;

  return info;
}

function getCurrentFeesUsd(tokenAddresses, fees, infoTokens) {
  if (!fees || !infoTokens) {
    return bigNumberify(0);
  }

  let currentFeesUsd = bigNumberify(0);
  for (let i = 0; i < tokenAddresses.length; i++) {
    const tokenAddress = tokenAddresses[i];
    const tokenInfo = infoTokens[tokenAddress];
    if (!tokenInfo || !tokenInfo.contractMinPrice) {
      continue;
    }

    const feeUsd = fees[i].mul(tokenInfo.contractMinPrice).div(expandDecimals(1, tokenInfo.decimals));
    currentFeesUsd = currentFeesUsd.add(feeUsd);
  }

  return currentFeesUsd;
}

export default function DashboardV2() {
  const { active, library } = useWeb3React();
  const { chainId } = useChainId();

  const chainName = getChainName(chainId);

  // todo: we'll need to grab the icon and add it in here
  const EquityTokenAsAssetInfo = { symbol: "EQUITY", decimals: 18, address: getContract(chainId, "EquityToken") };

  // const positionStatsUrl = getServerUrl(chainId, "/position_stats");
  // const { data: positionStats } = useSWR([positionStatsUrl], {
  //   fetcher: (...args) => fetch(...args).then((res) => res.json()),
  // });

  const positionStats = usePositionStats();

  const volumeInfo = useHourlyVolume();

  // const hourlyVolumeUrl = getServerUrl(chainId, "/hourly_volume");
  // const { data: hourlyVolume } = useSWR([hourlyVolumeUrl], {
  //   fetcher: (...args) => fetch(...args).then((res) => res.json()),
  // });

  const totalVolumeSum = useTotalVolume();

  // const totalVolumeUrl = getServerUrl(chainId, "/total_volume");
  // const { data: totalVolume } = useSWR([totalVolumeUrl], {
  //   fetcher: (...args) => fetch(...args).then((res) => res.json()),
  // });

  // const totalVolumeSum = getTotalVolumeSum(totalVolume);

  const totalFees = useTotalFees();

  let { total: totalEqualSupply, outstandingSupply } = useTotalEqualSupply();
  let { total: totalVEEqualSupply } = useTotalVEEqualSupply();

  const { data: mvdEqualTreasuryHoldings, mutate: updateMvdEqualTreasuryHoldings } = useMvdEqualTreasuryHoldings(
    chainId,
    active
  );
  const { data: mvdEquityTreasuryHoldings, mutate: updateMvdEquityTreasuryHoldings } = useMvdEquityTreasuryHoldings(
    chainId,
    active
  );
  const { data: equalTreasuryHoldings, mutate: updateEqualTreasuryHoldings } = useEqualMultisigHoldings(
    chainId,
    active
  );

  const { data: protocolOwnLiquidity, mutate: updateProtocolOwnLiquidity } = useProtocolOwnLiquidity(chainId, active);

  let totalLongPositionSizes;
  let totalShortPositionSizes;
  if (positionStats && positionStats.totalLongPositionSizes && positionStats.totalShortPositionSizes) {
    totalLongPositionSizes = positionStats.totalLongPositionSizes;
    // totalLongPositionSizes = bigNumberify(positionStats.totalLongPositionSizes);
    totalShortPositionSizes = positionStats.totalShortPositionSizes;
    // totalShortPositionSizes = bigNumberify(positionStats.totalShortPositionSizes);
  }

  //const volumeInfo = getVolumeInfo(hourlyVolume);

  const whitelistedTokens = getWhitelistedTokens(chainId);
  const whitelistedTokenAddresses = whitelistedTokens.map((token) => token.address);
  const tokenList = whitelistedTokens.filter((t) => !t.isWrapped);

  const readerAddress = getContract(chainId, "Reader");
  const vaultAddress = getContract(chainId, "Vault");
  const equityManagerAddress = getContract(chainId, "EquityManager");

  const equalAddress = getContract(chainId, "EQUAL");
  const equityAddress = getContract(chainId, "EQUITY");
  const usdeAddress = getContract(chainId, "USDE");

  const tokensForSupplyQuery = [equalAddress, equityAddress, usdeAddress];

  const { data: aums } = useSWR([`Dashboard:getAums:${active}`, chainId, equityManagerAddress, "getAums"], {
    fetcher: fetcher(library, EquityManager),
  });

  const { data: fees } = useSWR([`Dashboard:fees:${active}`, chainId, readerAddress, "getFees", vaultAddress], {
    fetcher: fetcher(library, Reader, [whitelistedTokenAddresses]),
  });

  const { data: totalSupplies } = useSWR(
    [`Dashboard:totalSupplies:${active}`, chainId, readerAddress, "getTokenBalancesWithSupplies", AddressZero],
    {
      fetcher: fetcher(library, Reader, [tokensForSupplyQuery]),
    }
  );

  const { data: totalTokenWeights } = useSWR(
    [`EquitySwap:totalTokenWeights:${active}`, chainId, vaultAddress, "totalTokenWeights"],
    {
      fetcher: fetcher(library, Vault),
    }
  );

  const { infoTokens } = useInfoTokens(library, chainId, active, undefined, undefined);

  // const eth = infoTokens[getTokenBySymbol(chainId, "ETH").address];
  const currentFeesUsd = getCurrentFeesUsd(whitelistedTokenAddresses, fees, infoTokens);

  const feeHistory = getFeeHistory(chainId);
  // const shouldIncludeCurrrentFees = feeHistory.length && parseInt(Date.now() / 1000) - feeHistory[0].to > 60 * 60;
  const shouldIncludeCurrrentFees = true;
  let totalFeesDistributed = shouldIncludeCurrrentFees
    ? parseFloat(bigNumberify(formatAmount(currentFeesUsd, USD_DECIMALS - 2, 0, false)).toNumber()) / 100
    : 0;
  for (let i = 0; i < feeHistory.length; i++) {
    totalFeesDistributed += parseFloat(feeHistory[i].feeUsd);
  }

  const { equalPrice } = useEqualPrice(chainId, { fantom: chainId === FTM ? library : undefined }, active);

  let { total: totalEqualInLiquidity } = useTotalEqualInLiquidity(chainId, active);

  let { fantom: fantomStakedEqual, total: totalStakedEqual } = useTotalEqualStaked();
  let { total: excludedEqualSupply } = useTotalEqualExcludedFromSupply();

  let aum;
  if (aums && aums.length > 0) {
    aum = aums[0].add(aums[1]).div(2);
  }

  let equityPrice;
  let equitySupply;
  let equityMarketCap;
  if (aum && totalSupplies && totalSupplies[3]) {
    equitySupply = totalSupplies[3];
    equityPrice =
      aum && aum.gt(0) && equitySupply.gt(0)
        ? aum.mul(expandDecimals(1, EQUITY_DECIMALS)).div(equitySupply)
        : expandDecimals(1, USD_DECIMALS);
    equityMarketCap = equityPrice.mul(equitySupply).div(expandDecimals(1, EQUITY_DECIMALS));
  }

  let tvl;
  if (equityMarketCap && equalPrice && totalStakedEqual) {
    tvl = equityMarketCap.add(equalPrice.mul(totalStakedEqual).div(expandDecimals(1, EQUAL_DECIMALS)));
  }

  let adjustedUsdeSupply = bigNumberify(0);

  for (let i = 0; i < tokenList.length; i++) {
    const token = tokenList[i];
    const tokenInfo = infoTokens[token.address];
    if (tokenInfo && tokenInfo.usdeAmount) {
      adjustedUsdeSupply = adjustedUsdeSupply.add(tokenInfo.usdeAmount);
    }
  }

  const getWeightText = (tokenInfo) => {
    if (
      !tokenInfo.weight ||
      !tokenInfo.usdeAmount ||
      !adjustedUsdeSupply ||
      adjustedUsdeSupply.eq(0) ||
      !totalTokenWeights
    ) {
      return "...";
    }

    const currentWeightBps = tokenInfo.usdeAmount.mul(BASIS_POINTS_DIVISOR).div(adjustedUsdeSupply);
    const targetWeightBps = tokenInfo.weight.mul(BASIS_POINTS_DIVISOR).div(totalTokenWeights);

    const weightText = `${formatAmount(currentWeightBps, 2, 2, false)}% / ${formatAmount(
      targetWeightBps,
      2,
      2,
      false
    )}%`;

    return (
      <TooltipComponent
        handle={weightText}
        position="right-bottom"
        renderContent={() => {
          return (
            <>
              Current Weight: {formatAmount(currentWeightBps, 2, 2, false)}%<br />
              Target Weight: {formatAmount(targetWeightBps, 2, 2, false)}%<br />
              <br />
              {currentWeightBps.lt(targetWeightBps) && (
                <div>
                  {tokenInfo.symbol} is below its target weight.
                  <br />
                  <br />
                  Get lower fees to{" "}
                  <Link to="/buy_equity" target="_blank" rel="noopener noreferrer">
                    Mint EQUITY
                  </Link>{" "}
                  with {tokenInfo.symbol},&nbsp; and to{" "}
                  <Link to="/trade" target="_blank" rel="noopener noreferrer">
                    swap
                  </Link>{" "}
                  {tokenInfo.symbol} for other tokens.
                </div>
              )}
              {currentWeightBps.gt(targetWeightBps) && (
                <div>
                  {tokenInfo.symbol} is above its target weight.
                  <br />
                  <br />
                  Get lower fees to{" "}
                  <Link to="/trade" target="_blank" rel="noopener noreferrer">
                    swap
                  </Link>{" "}
                  tokens for {tokenInfo.symbol}.
                </div>
              )}
              <br />
              <div>
                <a href="https://docs.equalizer.exchange/tokenomics/equity" target="_blank" rel="noopener noreferrer">
                  More Info
                </a>
              </div>
            </>
          );
        }}
      />
    );
  };

  let stakedPercent = 0;
  let veEqualSupply = 0;
  let equalSupply = 0;

  try {
    if (outstandingSupply && !outstandingSupply.isZero() && excludedEqualSupply && !excludedEqualSupply.isZero() && totalVEEqualSupply && !totalVEEqualSupply.isZero()) {
      veEqualSupply = totalVEEqualSupply.mul(100).div(outstandingSupply.add(totalVEEqualSupply)).toNumber();
    }
  } catch (error) {
  }

  let equalMarketCap;
  try {
    if (equalPrice && outstandingSupply && excludedEqualSupply && outstandingSupply && totalVEEqualSupply) {
      equalMarketCap = equalPrice.mul(outstandingSupply).div(expandDecimals(1, EQUAL_DECIMALS));
    }
  } catch (error) {
  }

  if (outstandingSupply && !outstandingSupply.isZero() && excludedEqualSupply && !excludedEqualSupply.isZero() && totalVEEqualSupply && !totalVEEqualSupply.isZero()) {
    equalSupply = outstandingSupply.mul(100).div(outstandingSupply.add(totalVEEqualSupply)).toNumber();
  }

  try {
    if (outstandingSupply && !outstandingSupply.isZero() && excludedEqualSupply && !excludedEqualSupply.isZero() && !totalStakedEqual.isZero()) {
      stakedPercent = totalStakedEqual.mul(100).div(outstandingSupply).toNumber();
    }
  } catch (error) {
  }

  let liquidityPercent = 0;
  try {
    if (totalEqualSupply && !totalEqualSupply.isZero() && excludedEqualSupply && !excludedEqualSupply.isZero() && totalEqualInLiquidity) {
      liquidityPercent = totalEqualInLiquidity.mul(100).div(totalEqualSupply.sub(excludedEqualSupply)).toNumber();
    }
  } catch (error) {
  }

  let mvdEqualTreasuryHoldingsPercent = 0;
  try {
    if (totalEqualSupply && !totalEqualSupply.isZero() && excludedEqualSupply && !excludedEqualSupply.isZero() && mvdEqualTreasuryHoldings) {
      mvdEqualTreasuryHoldingsPercent = mvdEqualTreasuryHoldings.mul(100).div(totalEqualSupply.sub(excludedEqualSupply)).toNumber();
    }
  } catch (error) {
  }

  let equalTreasuryHoldingsPercent = 0;
  try {
    if (totalEqualSupply && !totalEqualSupply.isZero() && excludedEqualSupply && !excludedEqualSupply.isZero() && equalTreasuryHoldings && totalVEEqualSupply && !totalVEEqualSupply.isZero()) {
      equalTreasuryHoldingsPercent = excludedEqualSupply.mul(100).div(totalEqualSupply.add(totalVEEqualSupply).sub(excludedEqualSupply)).toNumber();
    }
  } catch (error) {
  }


  let notStakedPercent =
    100 - stakedPercent - liquidityPercent - mvdEqualTreasuryHoldingsPercent - equalTreasuryHoldingsPercent;

  let equalDistributionData = [
    {
      name: "Vote-Escrowed EQUAL",
      value: veEqualSupply,
      color: "purple",
    },
    {
      name: "Other EQUAL holders",
      value: equalSupply-liquidityPercent,
      color: "#e5bf29",
    },
    {
      name: "EQUAL/FTM Liquidity",
      value: liquidityPercent,
      color: "pink",
    },
    // {
    //   name: "not staked",
    //   value: notStakedPercent,
    //   color: "#887200db",
    // },
    // {
    //   name: "equal multisig wallet",
    //   value: equalTreasuryHoldingsPercent,
    //   color: "#324532",
    // },
  ];
  const totalStatsStartDate = "01 June 2023";

  let stableEquity = 0;
  let totalEquity = 0;

  let equityPool = tokenList.map((token) => {
    const tokenInfo = infoTokens[token.address];
    if (tokenInfo.usdeAmount && adjustedUsdeSupply && adjustedUsdeSupply > 0) {
      const currentWeightBps = tokenInfo.usdeAmount.mul(BASIS_POINTS_DIVISOR).div(adjustedUsdeSupply);
      if (tokenInfo.isStable) {
        stableEquity += parseFloat(`${formatAmount(currentWeightBps, 2, 2, false)}`);
      }
      totalEquity += parseFloat(`${formatAmount(currentWeightBps, 2, 2, false)}`);
      return {
        fullname: token.name,
        name: token.symbol,
        value: parseFloat(`${formatAmount(currentWeightBps, 2, 2, false)}`),
      };
    }
    return null;
  });

  let stablePercentage = totalEquity > 0 ? ((stableEquity * 100) / totalEquity).toFixed(2) : "0.0";

  equityPool = equityPool.filter(function (element) {
    return element !== null;
  });

  equityPool = equityPool.sort(function (a, b) {
    if (a.value < b.value) return 1;
    else return -1;
  });

  equalDistributionData = equalDistributionData.sort(function (a, b) {
    if (a.value < b.value) return 1;
    else return -1;
  });

  const [equalActiveIndex, setEQUALActiveIndex] = useState(null);

  const onEQUALDistributionChartEnter = (_, index) => {
    setEQUALActiveIndex(index);
  };

  const onEQUALDistributionChartLeave = (_, index) => {
    setEQUALActiveIndex(null);
  };

  const [equityActiveIndex, setEQUITYActiveIndex] = useState(null);

  const onEQUITYPoolChartEnter = (_, index) => {
    setEQUITYActiveIndex(index);
  };

  const onEQUITYPoolChartLeave = (_, index) => {
    setEQUITYActiveIndex(null);
  };

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div className="stats-label">
          <div className="stats-label-color" style={{ backgroundColor: payload[0].color }}></div>
          {payload[0].value}% {payload[0].name}
        </div>
      );
    }

    return null;
  };

  return (
    <SEO title={getPageTitle("Dashboard")}>
      <div className="default-container DashboardV2 page-layout">
        <div className="section-title-block mb-3 sectionsmallscreen">
          <div className="section-title-icon section-title-iconsmall">
            <img src={statsBigIcon} alt="statsBigIcon" />
          </div>
          <div className="section-title-content">
            <div className="Page-title">
              Stats{" "}
              {chainId === FTM && (
                <img src={fantom24Icon} style={{ width: "24px", height: "24px" }} alt="fantom24Icon" />
              )}
            </div>
            <div className="Page-description">
              {chainName} Total Stats begin on {totalStatsStartDate}.<br /> In-depth statistics:{" "}
              {chainId === FTM && (
                <a
                  href="https://equity-stats.equalizer.exchange"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="ahreftextcolor"
                >
                  https://equity-stats.equalizer.exchange
                </a>
              )}
            </div>
          </div>
        </div>
        <div className="DashboardV2-content">
          <div className="DashboardV2-cards ">
            <div className="App-card ">
              <div className="App-card-title">Overview</div>
              <div className="App-card-divider"></div>
              <div className="App-card-content">
                <div className="App-card-row">
                  <div className="label">AUM</div>
                  <div>
                    <TooltipComponent
                      handle={`$${formatAmount(tvl, USD_DECIMALS, 0, true)}`}
                      position="right-bottom"
                      renderContent={() =>
                        `Assets Under Management: EQUAL staked (All chains) + EQUITY pool (${chainName})`
                      }
                    />
                  </div>
                </div>
                <div className="App-card-row">
                  <div className="label">EQUITY Pool</div>
                  <div>
                    <TooltipComponent
                      handle={`$${formatAmount(aum, USD_DECIMALS, 0, true)}`}
                      position="right-bottom"
                      renderContent={() => `Total value of tokens in EQUITY pool (${chainName})`}
                    />
                  </div>
                </div>
                <div className="App-card-row">
                  <div className="label">24h Volume</div>
                  <div>${formatAmount(volumeInfo, USD_DECIMALS, 0, true)}</div>
                </div>
                {feeHistory.length ? (
                  <div className="App-card-row">
                    <div className="label">Fees since {formatDate(feeHistory[0].to)}</div>
                    <div>${formatAmount(currentFeesUsd, USD_DECIMALS, 2, true)}</div>
                  </div>
                ) : null}
                <div className="App-card-row">
                  <div className="label">Protocol fees since last claim</div>
                  {/* <div className="label">Collected fees from -</div> */}
                  <div>${numberWithCommas(totalFeesDistributed.toFixed(0))}</div>
                </div>
              </div>
            </div>
            <div className="App-card">
              <div className="App-card-title">Total Stats</div>
              <div className="App-card-divider"></div>
              <div className="App-card-content">
                <div className="App-card-row">
                  <div className="label">Total collected fees</div>
                  <div>${formatAmount(totalFees, USD_DECIMALS, 0, true)}</div>
                </div>
                <div className="App-card-row">
                  <div className="label">Total Volume</div>
                  <div>${formatAmount(totalVolumeSum, USD_DECIMALS, 0, true)}</div>
                </div>
                {/* <div className="App-card-row">
                  <div className="label">Protocol own Liquidity</div>
                  <div>
                    {formatAmount(protocolOwnLiquidity, 18, 0, true)} EQUITY ($
                    {formatAmount(protocolOwnLiquidityUsd, USD_DECIMALS, 0, true)})
                  </div>
                </div> */}

                {/* <div className="App-card-row">
                  <div className="label">Equity Treasury owned Liquidity</div>
                  <div>
                    {formatAmount(mvdEquityTreasuryHoldings, 18, 0, true)} EQUITY ($
                    {formatAmount(mvdEquityTreasuryHoldingsUsd, USD_DECIMALS, 0, true)})
                  </div>
                </div> */}
                {/* <div className="App-card-row">
                  <div className="label">EQUAL Treasury EQUAL holdings</div>
                  <div>
                    {formatAmount(mvdEqualTreasuryHoldings, 18, 0, true)} EQUAL ($
                    {formatAmount(mvdEqualTreasuryHoldingsUsd, USD_DECIMALS, 0, true)})
                  </div>
                </div> */}
                {/* <div className="App-card-row">
                  <div className="label">Floor Price Fund</div>
                  <div>${formatAmount(totalFloorPriceFundUsd, 30, 0, true)}</div>
                </div> */}
              </div>
            </div>
          </div>

          <div className="section-title-block  mt-5 mb-4 sectionsmallscreen">
            <div className="section-title-icon section-title-iconsmall">
              <img src={tokensBigIcon} alt="statsBigIcon" />
            </div>
            <div className="section-title-content">
              <div className="Page-title">
                Tokens{" "}
                {chainId === FTM && (
                  <img src={fantom24Icon} style={{ width: "24px", height: "24px" }} alt="fantom24Icon" />
                )}
              </div>
              <div className="Page-description">Equity's Platform and Liquidity Token Index.</div>
            </div>
          </div>

          <div className="DashboardV2-token-cards">
            <div className="stats-wrapper stats-wrapper--equal">
              <div className="App-card">
                <div className="stats-block">
                  <div className="App-card-title">
                    <div className="App-card-title-mark">
                      <div className="App-card-title-mark-icon">
                        <img src={equal40Icon} alt="equal40Icon" style={{ width: "40px", height: "40px" }} />
                      </div>
                      <div className="App-card-title-mark-info">
                        <div className="App-card-title-mark-title">EQUAL</div>
                        <div className="App-card-title-mark-subtitle">EQUAL</div>
                      </div>
                      <div>
                        <AssetDropdown assetSymbol="EQUAL" />
                      </div>
                    </div>
                  </div>
                  <div className="App-card-divider"></div>
                  <div className="App-card-content">
                    <div className="App-card-row">
                      <div className="label">Price</div>
                      <div>
                        {!equalPrice && "..."}
                        {equalPrice && (
                          <TooltipComponent
                            position="right-bottom"
                            className="nowrap"
                            handle={"$" + formatAmount(equalPrice, USD_DECIMALS, EQUALEQUITY_DISPLAY_DECIMALS, true)}
                            renderContent={() => (
                              <>
                                Price on Fantom: $
                                {formatAmount(equalPrice, USD_DECIMALS, EQUALEQUITY_DISPLAY_DECIMALS, true)}
                              </>
                            )}
                          />
                        )}
                      </div>
                    </div>
                    <div className="App-card-row">
                      <div className="label">Circulating Equal Supply</div>
                      <div>
                        {formatAmount(
                          totalEqualSupply ? outstandingSupply : BigNumber.from(0),
                          EQUAL_DECIMALS,
                          0,
                          true
                        )}{" "}
                        EQUAL
                      </div>
                    </div>
                    <div className="App-card-row">
                      <div className="label">veEqual Supply</div>
                      <div>
                        {formatAmount(
                          totalVEEqualSupply ? totalVEEqualSupply : BigNumber.from(0),
                          EQUAL_DECIMALS,
                          0,
                          true
                        )}{" "}
                        veEQUAL
                      </div>
                    </div>
                    {/* <div className="App-card-row">
                      <div className="label">Total Staked</div>
                      <div>
                        {
                          <TooltipComponent
                            position="right-bottom"
                            className="nowrap"
                            handle={`$${formatAmount(stakedEqualSupplyUsd, USD_DECIMALS, 0, true)}`}
                            renderContent={() => (
                              <>Staked on Fantom: {formatAmount(fantomStakedEqual, EQUAL_DECIMALS, 0, true)} EQUAL</>
                            )}
                          />
                        }
                      </div>
                    </div> */}
                    <div className="App-card-row">
                      <div className="label">Circulating Market Cap</div>
                      <div>${formatAmount(equalMarketCap, USD_DECIMALS, 0, true)}</div>
                    </div>
                  </div>
                </div>
                <div className="stats-piechart" onMouseLeave={onEQUALDistributionChartLeave}>
                  {equalDistributionData.length > 0 && (
                    <PieChart width={210} height={210}>
                      <Pie
                        data={equalDistributionData}
                        cx={100}
                        cy={100}
                        innerRadius={73}
                        outerRadius={80}
                        fill="#8884d8"
                        dataKey="value"
                        startAngle={90}
                        endAngle={-270}
                        paddingAngle={2}
                        onMouseEnter={onEQUALDistributionChartEnter}
                        onMouseOut={onEQUALDistributionChartLeave}
                        onMouseLeave={onEQUALDistributionChartLeave}
                      >
                        {equalDistributionData.map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={entry.color}
                            style={{
                              filter:
                                equalActiveIndex === index
                                  ? `drop-shadow(0px 0px 6px ${hexToRgba(entry.color, 0.7)})`
                                  : "none",
                              cursor: "pointer",
                            }}
                            stroke={entry.color}
                            strokeWidth={equalActiveIndex === index ? 1 : 1}
                          />
                        ))}
                      </Pie>
                      <text x={"50%"} y={"50%"} fill="white" textAnchor="middle" dominantBaseline="middle">
                        Distribution
                      </text>
                      <Tooltip content={<CustomTooltip />} />
                    </PieChart>
                  )}
                </div>
              </div>
              <div className="App-card">
                <div className="stats-block">
                  <div className="App-card-title">
                    <div className="App-card-title-mark">
                      <div className="App-card-title-mark-icon">
                        <img src={equity40Icon} alt="equity40Icon" style={{ width: "40px", height: "40px" }} />
                      </div>
                      <div className="App-card-title-mark-info">
                        <div className="App-card-title-mark-title">EQUITY</div>
                        <div className="App-card-title-mark-subtitle">EQUITY</div>
                      </div>
                      <div>
                        <AssetDropdown assetSymbol="EQUITY" assetInfo={EquityTokenAsAssetInfo} />
                      </div>
                    </div>
                  </div>
                  <div className="App-card-divider"></div>
                  <div className="App-card-content">
                    <div className="App-card-row">
                      <div className="label">Price</div>
                      <div>${formatAmount(equityPrice, USD_DECIMALS, EQUALEQUITY_DISPLAY_DECIMALS, true)}</div>
                    </div>
                    <div className="App-card-row">
                      <div className="label">Supply</div>
                      <div>{formatAmount(equitySupply, EQUITY_DECIMALS, 0, true)} EQUITY</div>
                    </div>
                    {/*     <div className="App-card-row">
                      <div className="label">Total Staked</div>
                      <div>${formatAmount(equityMarketCap, USD_DECIMALS, 0, true)}</div>
                    </div> */}
                    <div className="App-card-row">
                      <div className="label">Market Cap</div>
                      <div>${formatAmount(equityMarketCap, USD_DECIMALS, 0, true)}</div>
                    </div>
                    <div className="App-card-row">
                      <div className="label">Stablecoin Percentage</div>
                      <div>{stablePercentage}%</div>
                    </div>
                  </div>
                </div>
                <div className="stats-piechart" onMouseOut={onEQUITYPoolChartLeave}>
                  {equityPool.length > 0 && (
                    <PieChart width={210} height={210}>
                      <Pie
                        data={equityPool}
                        cx={100}
                        cy={100}
                        innerRadius={73}
                        outerRadius={80}
                        fill="#8884d8"
                        dataKey="value"
                        startAngle={90}
                        endAngle={-270}
                        onMouseEnter={onEQUITYPoolChartEnter}
                        onMouseOut={onEQUITYPoolChartLeave}
                        onMouseLeave={onEQUITYPoolChartLeave}
                        paddingAngle={2}
                      >
                        {equityPool.map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={EQUITYPOOLCOLORS[entry.name]}
                            style={{
                              filter:
                                equityActiveIndex === index
                                  ? `drop-shadow(0px 0px 6px ${hexToRgba(EQUITYPOOLCOLORS[entry.name], 0.7)})`
                                  : "none",
                              cursor: "pointer",
                            }}
                            stroke={EQUITYPOOLCOLORS[entry.name]}
                            strokeWidth={equityActiveIndex === index ? 1 : 1}
                          />
                        ))}
                      </Pie>
                      <text x={"50%"} y={"50%"} fill="white" textAnchor="middle" dominantBaseline="middle">
                        EQUITY Pool
                      </text>
                      <Tooltip content={<CustomTooltip />} />
                    </PieChart>
                  )}
                </div>
              </div>
            </div>
            <div className="token-table-wrapper App-card">
              <div className="App-card-title">
                Equity Liquidity Pool{" "}
                {chainId === FTM && (
                  <img src={fantom16Icon} style={{ width: "16px", height: "16px" }} alt="fantom16Icon" />
                )}
              </div>
              <div className="App-card-divider"></div>
              <table className="token-table">
                <thead>
                  <tr>
                    <th>TOKEN</th>
                    <th>PRICE</th>
                    <th>POOL</th>
                    <th>WEIGHT</th>
                    <th>UTILIZATION</th>
                  </tr>
                </thead>
                <tbody>
                  {tokenList.map((token) => {
                    const tokenInfo = infoTokens[token.address];
                    let utilization = bigNumberify(0);
                    if (tokenInfo && tokenInfo.reservedAmount && tokenInfo.poolAmount && tokenInfo.poolAmount.gt(0)) {
                      utilization = tokenInfo.reservedAmount.mul(BASIS_POINTS_DIVISOR).div(tokenInfo.poolAmount);
                    }
                    const maxUsdeAmount = tokenInfo.maxUsdeAmount;

                    var tokenImage = null;

                    try {
                      if (token.symbol.toLowerCase() === "ftm" || token.symbol.toLowerCase() === "wftm") {
                        tokenImage = require("../../img/logo-fantom.png");
                      }
                      else {
                        tokenImage = require("../../img/" + token.symbol + ".png");
                      }
                    } catch (error) {
                      console.error(error);
                    }

                    return (
                      <tr key={token.symbol}>
                        <td>
                          <div className="token-symbol-wrapper">
                            <div className="App-card-title-info">
                              <div className="App-card-title-info-icon">
                                <img src={tokenImage && tokenImage.default} alt={token.symbol} width="40px" />
                              </div>
                              <div className="App-card-title-info-text">
                                <div className="App-card-info-title">{token.name}</div>
                                <div className="App-card-info-subtitle">{token.symbol}</div>
                              </div>
                              <div>
                                <AssetDropdown assetSymbol={token.symbol} assetInfo={token} />
                              </div>
                            </div>
                          </div>
                        </td>
                        {/* <td>${(tokenInfo.symbol === "USDC" || tokenInfo.symbol === "DAI" || tokenInfo.symbol === "USDT") ? Math.round(formatKeyAmount(tokenInfo, "maxPrimaryPrice", USD_DECIMALS, 2, true)).toFixed(2) : formatKeyAmount(tokenInfo, "maxPrimaryPrice", USD_DECIMALS, tokenInfo.displayDecimals, true)}</td> */}
                        <td>
                          $
                          {formatKeyAmount(tokenInfo, "maxPrimaryPrice", USD_DECIMALS, tokenInfo.displayDecimals, true)}
                        </td>
                        <td>
                          <TooltipComponent
                            handle={`$${formatKeyAmount(tokenInfo, "managedUsd", USD_DECIMALS, 0, true)}`}
                            position="right-bottom"
                            renderContent={() => {
                              return (
                                <>
                                  Pool Amount: {formatKeyAmount(tokenInfo, "managedAmount", token.decimals, 2, true)}{" "}
                                  {token.symbol}
                                  <br />
                                  <br />
                                  Max {tokenInfo.symbol} Capacity: ${formatAmount(maxUsdeAmount, 18, 0, true)}
                                </>
                              );
                            }}
                          />
                        </td>
                        <td>{getWeightText(tokenInfo)}</td>
                        <td>{formatAmount(utilization, 2, 2, false)}%</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="token-grid">
              {tokenList.map((token) => {
                const tokenInfo = infoTokens[token.address];
                let utilization = bigNumberify(0);
                if (tokenInfo && tokenInfo.reservedAmount && tokenInfo.poolAmount && tokenInfo.poolAmount.gt(0)) {
                  utilization = tokenInfo.reservedAmount.mul(BASIS_POINTS_DIVISOR).div(tokenInfo.poolAmount);
                }
                const maxUsdeAmount = tokenInfo.maxUsdeAmount;
                var tokenImage = null;

                try {
                      if (token.symbol.toLowerCase() === "ftm" || token.symbol.toLowerCase() === "wftm") {
                        tokenImage = require("../../img/logo-fantom.png");
                      }
                      else {
                        tokenImage = require("../../img/" + token.symbol + ".png");
                      }
                } catch (error) {
                  console.error(error);
                }

                return (
                  <div className="App-card" key={token.symbol}>
                    <div className="App-card-title">
                      <div style={{ display: "flex" }}>
                        <img src={tokenImage && tokenImage.default} alt={token.symbol} width="40px" />
                        <span className="mt-2 mx-1">{token.symbol}</span>
                        <div className="mt-2">
                          <AssetDropdown assetSymbol={token.symbol} assetInfo={token} />
                        </div>
                      </div>
                    </div>
                    <div className="App-card-divider"></div>
                    <div className="App-card-content">
                      <div className="App-card-row">
                        <div className="label">Price</div>
                        <div>
                          ${formatKeyAmount(tokenInfo, "minPrice", USD_DECIMALS, tokenInfo.displayDecimals, true)}
                        </div>
                      </div>
                      <div className="App-card-row">
                        <div className="label">Pool</div>
                        <div>
                          <TooltipComponent
                            handle={`$${formatKeyAmount(tokenInfo, "managedUsd", USD_DECIMALS, 0, true)}`}
                            position="right-bottom"
                            renderContent={() => {
                              return (
                                <>
                                  Pool Amount: {formatKeyAmount(tokenInfo, "managedAmount", token.decimals, 2, true)}{" "}
                                  {token.symbol}
                                  <br />
                                  <br />
                                  Max {tokenInfo.symbol} Capacity: ${formatAmount(maxUsdeAmount, 18, 0, true)}
                                </>
                              );
                            }}
                          />
                        </div>
                      </div>
                      <div className="App-card-row">
                        <div className="label">Weight</div>
                        <div>{getWeightText(tokenInfo)}</div>
                      </div>
                      <div className="App-card-row">
                        <div className="label">Utilization</div>
                        <div>{formatAmount(utilization, 2, 2, false)}%</div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </SEO>
  );
}
