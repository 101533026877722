import React, { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";

import Modal from "../../components/Modal/Modal";
import Checkbox from "../../components/Checkbox/Checkbox";
import Tooltip from "../../components/Tooltip/Tooltip";
import Footer from "../../Footer";
import earnedBigIcon from "../../img/earnlogo.svg";
import Vault from "../../abis/Vault.json";
import Reader from "../../abis/Reader.json";
import Vester from "../../abis/Vester.json";
import RewardRouter from "../../abis/RewardRouter.json";
import RewardReader from "../../abis/RewardReader.json";
import Token from "../../abis/Token.json";
import EquityManager from "../../abis/EquityManager.json";
import EquityFarm from "../../abis/EquityFarm.json";
import EquityToken from "../../abis/EquityToken.json";
import { useTotalEquityTokenStakedFarm } from "../../Api";

import { ethers } from "ethers";
import {
  helperToast,
  bigNumberify,
  fetcher,
  formatAmount,
  formatKeyAmount,
  formatAmountFree,
  getChainName,
  expandDecimals,
  parseValue,
  approveTokens,
  useChainId,
  EQUITY_DECIMALS,
  USD_DECIMALS,
  BASIS_POINTS_DIVISOR,
  FTM,
  PLACEHOLDER_ACCOUNT,
  getBalanceAndSupplyData,
  getDepositBalanceData,
  getVestingData,
  getStakingData,
  getProcessedData,
  getPageTitle,
} from "../../Helpers";
import { callContract, useEqualPrice, useTotalEqualStaked, useTotalEquityTokenSupply } from "../../Api";
import { getConstant } from "../../Constants";

import useSWR from "swr";

import { getContract } from "../../Addresses";

import "./FarmEquity.css";

const { AddressZero } = ethers.constants;

function StakeModal(props) {
  const {
    isVisible,
    setIsVisible,
    chainId,
    title,
    maxAmount,
    value,
    setValue,
    active,
    account,
    library,
    stakingTokenSymbol,
    stakingTokenAddress,
    equityFarmAddress,
    stakeMethodName,
    setPendingTxns,
  } = props;
  const [isStaking, setIsStaking] = useState(false);
  const [isApproving, setIsApproving] = useState(false);

  const { data: tokenAllowance } = useSWR(
    active && stakingTokenAddress && [active, chainId, stakingTokenAddress, "allowance", account, equityFarmAddress],
    {
      fetcher: fetcher(library, Token),
    }
  );

  let amount = parseValue(value, 18);
  const needApproval = equityFarmAddress !== AddressZero && tokenAllowance && amount && amount.gt(tokenAllowance);

  const getError = () => {
    if (!amount || amount.eq(0)) {
      return "ENTER AN AMOUNT";
    }
    if (maxAmount && amount.gt(maxAmount)) {
      return "MAX AMOUNT EXCEEDED";
    }
  };

  const onClickPrimary = () => {
    if (needApproval) {
      approveTokens({
        setIsApproving,
        library,
        tokenAddress: stakingTokenAddress,
        spender: equityFarmAddress,
        chainId,
      });
      return;
    }

    setIsStaking(true);
    const contract = new ethers.Contract(equityFarmAddress, EquityFarm.abi, library.getSigner());

    callContract(chainId, contract, stakeMethodName, [amount], {
      sentMsg: "Stake submitted!",
      failMsg: "Stake failed.",
      setPendingTxns,
    })
      .then(async (res) => {
        setIsVisible(false);
      })
      .finally(() => {
        setIsStaking(false);
      });
  };

  const isPrimaryEnabled = () => {
    const error = getError();
    if (error) {
      return false;
    }
    if (isApproving) {
      return false;
    }
    if (isStaking) {
      return false;
    }
    return true;
  };

  const getPrimaryText = () => {
    const error = getError();
    if (error) {
      return error;
    }
    if (isApproving) {
      return `Approving ${stakingTokenSymbol}...`;
    }
    if (needApproval) {
      return `Approve ${stakingTokenSymbol}`;
    }
    if (isStaking) {
      return "Staking...";
    }
    return "Stake";
  };

  return (
    <div className="StakeModal">
      <Modal isVisible={isVisible} setIsVisible={setIsVisible} label={title}>
        <div className="Exchange-swap-section">
          <div className="Exchange-swap-section-top">
            <div className="muted">
              <div className="Exchange-swap-usd">Stake</div>
            </div>
            <div className="muted align-right clickable" onClick={() => setValue(formatAmountFree(maxAmount, 18, 18))}>
              Max: {formatAmount(maxAmount, 18, 4, true)}
            </div>
          </div>
          <div className="Exchange-swap-section-bottom">
            <div>
              <input
                type="number"
                placeholder="0.0"
                className="Exchange-swap-input"
                value={value}
                onChange={(e) => setValue(e.target.value)}
              />
            </div>
            <div className="PositionEditor-token-symbol">{stakingTokenSymbol}</div>
          </div>
        </div>
        <div className="Exchange-swap-button-container">
          <button className="App-cta Exchange-swap-button" onClick={onClickPrimary} disabled={!isPrimaryEnabled()}>
            {getPrimaryText()}
          </button>
        </div>
      </Modal>
    </div>
  );
}

function UnstakeModal(props) {
  const {
    isVisible,
    setIsVisible,
    chainId,
    title,
    maxAmount,
    value,
    setValue,
    library,
    unstakingTokenSymbol,
    equityFarmAddress,
    unstakeMethodName,
    reservedAmount,
    setPendingTxns,
  } = props;
  const [isUnstaking, setIsUnstaking] = useState(false);

  let amount = parseValue(value, 18);

  const getError = () => {
    if (!amount) {
      return "ENTER AN AMOUNT";
    }
    if (amount.gt(maxAmount)) {
      return "MAX AMOUNT EXCEEDED";
    }
  };

  const onClickPrimary = () => {
    setIsUnstaking(true);
    const contract = new ethers.Contract(equityFarmAddress, EquityFarm.abi, library.getSigner());
    callContract(chainId, contract, unstakeMethodName, [amount], {
      sentMsg: "Unstake submitted!",
      failMsg: "Unstake failed.",
      successMsg: "Unstake completed!",
      setPendingTxns,
    })
      .then(async (res) => {
        setIsVisible(false);
      })
      .finally(() => {
        setIsUnstaking(false);
      });
  };

  const isPrimaryEnabled = () => {
    const error = getError();
    if (error) {
      return false;
    }
    if (isUnstaking) {
      return false;
    }
    return true;
  };

  const getPrimaryText = () => {
    const error = getError();
    if (error) {
      return error;
    }
    if (isUnstaking) {
      return "Unstaking...";
    }
    return "Unstake";
  };

  return (
    <div className="StakeModal">
      <Modal isVisible={isVisible} setIsVisible={setIsVisible} label={title}>
        <div className="Exchange-swap-section">
          <div className="Exchange-swap-section-top">
            <div className="muted">
              <div className="Exchange-swap-usd">Unstake</div>
            </div>
            <div className="muted align-right clickable" onClick={() => setValue(formatAmountFree(maxAmount, 18, 18))}>
              Max: {formatAmount(maxAmount, 18, 4, true)}
            </div>
          </div>
          <div className="Exchange-swap-section-bottom">
            <div>
              <input
                type="number"
                placeholder="0.0"
                className="Exchange-swap-input"
                value={value}
                onChange={(e) => setValue(e.target.value)}
              />
            </div>
            <div className="PositionEditor-token-symbol">{unstakingTokenSymbol}</div>
          </div>
        </div>
        {reservedAmount && reservedAmount.gt(0) && (
          <div className="Modal-note">
            You have {formatAmount(reservedAmount, 18, 2, true)} tokens reserved for vesting.
          </div>
        )}
        <div className="Exchange-swap-button-container">
          <button className="App-cta Exchange-swap-button" onClick={onClickPrimary} disabled={!isPrimaryEnabled()}>
            {getPrimaryText()}
          </button>
        </div>
      </Modal>
    </div>
  );
}

function ClaimButton(props) {
  const {
    chainId,
    library,
    equityFarmAddress,
    setPendingTxns,
  } = props;
  const [isClaimingRewards, setIsClaimingRewards] = useState(false);

  const isClaimRewardsEnabled = () => {
    return !isClaimingRewards;
  };

  const getPrimaryTextClaimRewards = () => {
    if (isClaimingRewards) {
      return `Claiming...`;
    }
    return "Claim";
  };

  const onClickClaimRewards = () => {
    setIsClaimingRewards(true);

    //const contract = new ethers.Contract(equityFarmAddress, EquityFarm.abi, library.getSigner());
    const contract = new ethers.Contract("0xE3D1A117dF7DCaC2eB0AC8219341bAd92f18dAC1", EquityFarm.abi, library.getSigner());
    callContract(
      chainId,
      contract,
      //"getReward",
      "claimRewards",
      [
        [equityFarmAddress],	///farmland
        [
        	["0x3fd3a0c85b70754efc07ac9ac0cbbdce664865a6"]	///equal
        ]
      ],
      {
        gasLimit: 2e6,
        sentMsg: "Claim submitted.",
        failMsg: "Claim failed.",
        successMsg: "Claim completed!",
        setPendingTxns,
      }
    )
    .then(async () => {})
      .finally(() => {
        setIsClaimingRewards(false);
      });
  };


  return (
    <button className="App-button-option App-card-option" onClick={() => onClickClaimRewards()} disabled={!isClaimRewardsEnabled()}>
      {getPrimaryTextClaimRewards()}
    </button>
  );
}

export default function FarmEquity({ setPendingTxns, connectWallet }) {
  const { active, library, account } = useWeb3React();
  const { chainId } = useChainId();

  const [isStakeModalVisible, setIsStakeModalVisible] = useState(false);
  const [stakeModalTitle, setStakeModalTitle] = useState("");
  const [stakeModalMaxAmount, setStakeModalMaxAmount] = useState(undefined);
  const [stakeValue, setStakeValue] = useState("");
  const [stakingTokenSymbol, setStakingTokenSymbol] = useState("");
  const [stakingTokenAddress, setStakingTokenAddress] = useState("");
  const [stakeMethodName, setStakeMethodName] = useState("");

  const [isUnstakeModalVisible, setIsUnstakeModalVisible] = useState(false);
  const [unstakeModalTitle, setUnstakeModalTitle] = useState("");
  const [unstakeModalMaxAmount, setUnstakeModalMaxAmount] = useState(undefined);
  const [unstakeModalReservedAmount, setUnstakeModalReservedAmount] = useState(undefined);
  const [unstakeValue, setUnstakeValue] = useState("");
  const [unstakingTokenSymbol, setUnstakingTokenSymbol] = useState("");
  const [unstakeMethodName, setUnstakeMethodName] = useState("");

  const rewardReaderAddress = getContract(chainId, "RewardReader");
  const readerAddress = getContract(chainId, "Reader");

  const vaultAddress = getContract(chainId, "Vault");
  const nativeTokenAddress = getContract(chainId, "NATIVE_TOKEN");
  const equalAddress = getContract(chainId, "EQUAL");
  const esEqualAddress = getContract(chainId, "ES_EQUAL");
  const bnEqualAddress = getContract(chainId, "BN_EQUAL");
  const equityAddress = getContract(chainId, "EQUITY");
  const equityTokenAddress = getContract(chainId, "EquityToken");
  const equityFarmAddress = getContract(chainId, "EquityFarm");

  const stakedEqualTrackerAddress = getContract(chainId, "StakedEqualTracker");
  const bonusEqualTrackerAddress = getContract(chainId, "BonusEqualTracker");
  const feeEqualTrackerAddress = getContract(chainId, "FeeEqualTracker");

  const stakedEquityTrackerAddress = getContract(chainId, "StakedEquityTracker");
  const feeEquityTrackerAddress = getContract(chainId, "FeeEquityTracker");

  const equityManagerAddress = getContract(chainId, "EquityManager");

  const equalVesterAddress = getContract(chainId, "EqualVester");
  const equityVesterAddress = getContract(chainId, "EquityVester");

  const vesterAddresses = [equalVesterAddress, equityVesterAddress];

  const nativeTokenSymbol = getConstant(chainId, "nativeTokenSymbol");
  const wrappedTokenSymbol = getConstant(chainId, "wrappedTokenSymbol");

  const walletTokens = [equalAddress, esEqualAddress, equityTokenAddress, equityAddress, stakedEqualTrackerAddress];
  const depositTokens = [
    equalAddress,
    esEqualAddress,
    stakedEqualTrackerAddress,
    bonusEqualTrackerAddress,
    bnEqualAddress,
    equityTokenAddress,
  ];
  const rewardTrackersForDepositBalances = [
    stakedEqualTrackerAddress,
    stakedEqualTrackerAddress,
    bonusEqualTrackerAddress,
    feeEqualTrackerAddress,
    feeEqualTrackerAddress,
    feeEquityTrackerAddress,
  ];
  const rewardTrackersForStakingInfo = [
    stakedEqualTrackerAddress,
    bonusEqualTrackerAddress,
    feeEqualTrackerAddress,
    stakedEquityTrackerAddress,
    feeEquityTrackerAddress,
  ];

  const { data: walletBalances } = useSWR(
    [
      `FarmEquity:walletBalances:${active}`,
      chainId,
      readerAddress,
      "getTokenBalancesWithSupplies",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: fetcher(library, Reader, [walletTokens]),
    }
  );

  const { data: depositBalances } = useSWR(
    [
      `FarmEquity:depositBalances:${active}`,
      chainId,
      rewardReaderAddress,
      "getDepositBalances",
      account || PLACEHOLDER_ACCOUNT,
    ],
    {
      fetcher: fetcher(library, RewardReader, [depositTokens, rewardTrackersForDepositBalances]),
    }
  );

  const { data: stakingInfo } = useSWR(
    [`FarmEquity:stakingInfo:${active}`, chainId, rewardReaderAddress, "getStakingInfo", account || PLACEHOLDER_ACCOUNT],
    {
      fetcher: fetcher(library, RewardReader, [rewardTrackersForStakingInfo]),
    }
  );

  // DONE
  const { data: stakedEquitySupply } = useSWR(
    [`FarmEquity:stakedEquitySupply:${active}`, chainId, equityTokenAddress, "balanceOf", equityFarmAddress],
    {
      fetcher: fetcher(library, Token),
    }
  );

  const { data: lastAddedLiq } = useSWR(
    [`FarmEquity:stakedEquitySupply:${active}`, chainId, equityTokenAddress, "lastAddedAt", account || PLACEHOLDER_ACCOUNT],
    {
      fetcher: fetcher(library, EquityToken),
    }
  );

    // DONE
    const { data: userStaked } = useSWR(
      [`FarmEquity:userStaked:${active}`, chainId, equityFarmAddress, "balanceOf", account || PLACEHOLDER_ACCOUNT],
      {
        fetcher: fetcher(library, EquityFarm),
      }
    );

  const { data: aums } = useSWR([`FarmEquity:getAums:${active}`, chainId, equityManagerAddress, "getAums"], {
    fetcher: fetcher(library, EquityManager),
  });

  const { data: nativeTokenPrice } = useSWR(
    [`FarmEquity:nativeTokenPrice:${active}`, chainId, vaultAddress, "getMinPrice", nativeTokenAddress],
    {
      fetcher: fetcher(library, Vault),
    }
  );

  const { data: vestingInfo } = useSWR(
    [`FarmEquity:vestingInfo:${active}`, chainId, readerAddress, "getVestingInfo", account || PLACEHOLDER_ACCOUNT],
    {
      fetcher: fetcher(library, Reader, [vesterAddresses]),
    }
  );

  const { equalPrice } = useEqualPrice(chainId, { fantom: chainId === FTM ? library : undefined }, active);

  let { total: totalEquityTokenSupply } = useTotalEquityTokenSupply();

  let { totalStakedFarm: totalEquityTokenStakedFarm, totalStakedFarmDollars: totalEquityTokenStakedFarmDollars, farmAprToken1: farmAprToken1, farmAprToken2: farmAprToken2, earnedToken1: earnedToken1, earnedToken2: earnedToken2 } = useTotalEquityTokenStakedFarm(account || PLACEHOLDER_ACCOUNT);

  let { total: equityTokenSupply } = useTotalEquityTokenSupply();

  const isEqualTransferEnabled = true;

  const userCanStake = lastAddedLiq ? Number(lastAddedLiq.toString()) + 900 < Date.now() / 1000 ? true : false : false

  let aum;
  if (aums && aums.length > 0) {
    aum = aums[0].add(aums[1]).div(2);
  }

  const { balanceData, supplyData } = getBalanceAndSupplyData(walletBalances);
  const depositBalanceData = getDepositBalanceData(depositBalances);
  const stakingData = getStakingData(stakingInfo);
  const vestingData = getVestingData(vestingInfo);

  const processedData = getProcessedData(
    balanceData,
    supplyData,
    depositBalanceData,
    stakingData,
    vestingData,
    aum,
    nativeTokenPrice,
    stakedEquitySupply,
    equalPrice,
    equityTokenSupply
  );

  let totalRewardTokens;
  if (processedData && processedData.bnEqualInFeeEqual && processedData.bonusEqualInFeeEqual) {
    totalRewardTokens = processedData.bnEqualInFeeEqual.add(processedData.bonusEqualInFeeEqual);
  }

  let totalRewardTokensAndEquity;
  if (totalRewardTokens && processedData && processedData.equityBalance) {
    totalRewardTokensAndEquity = totalRewardTokens.add(processedData.equityBalance);
  }

  let totalSupplyUsd;
  if (totalEquityTokenSupply && !totalEquityTokenSupply.isZero() && equalPrice) {
    totalSupplyUsd = totalEquityTokenSupply.mul(equalPrice).div(expandDecimals(1, 18));
  }

  let maxUnstakeableEqual = bigNumberify(0);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const showStakeEquityTokenModal = () => {
    setIsStakeModalVisible(true);
    setStakeModalTitle("Stake EQUITY");
    setStakeModalMaxAmount(equityTokensWallet);
    setStakeValue("");
    setStakingTokenSymbol("EQUITY");
    setStakingTokenAddress(equityTokenAddress);
    setStakeMethodName("deposit");
  };

  const showUnstakeEquityTokenModal = () => {
    setIsUnstakeModalVisible(true);
    setUnstakeModalTitle("Unstake EQUITY");
    let maxAmount = equityTokensStaked;
    if (
      equityTokensStaked &&
      vestingData &&
      vestingData.equalVesterPairAmount.gt(0) &&
      maxUnstakeableEqual &&
      maxUnstakeableEqual.lt(equityTokensStaked)
    ) {
      maxAmount = maxUnstakeableEqual;
    }
    setUnstakeModalMaxAmount(maxAmount);
    setUnstakeModalReservedAmount(vestingData.equalVesterPairAmount);
    setUnstakeValue("");
    setUnstakingTokenSymbol("EQUITY");
    setUnstakeMethodName("withdraw");
  };

  let earnMsg;
  if (totalRewardTokensAndEquity && totalRewardTokensAndEquity.gt(0)) {
    let equalAmountStr;
    if (processedData.equalInStakedEqual && processedData.equalInStakedEqual.gt(0)) {
      equalAmountStr = formatAmount(processedData.equalInStakedEqual, 18, 2, true) + " EQUAL";
    }
    let esEqualAmountStr;
    if (processedData.esEqualInStakedEqual && processedData.esEqualInStakedEqual.gt(0)) {
      esEqualAmountStr = formatAmount(processedData.esEqualInStakedEqual, 18, 2, true) + " esEQUAL";
    }
    let mpAmountStr;
    if (processedData.bonusEqualInFeeEqual && processedData.bnEqualInFeeEqual.gt(0)) {
      mpAmountStr = formatAmount(processedData.bnEqualInFeeEqual, 18, 2, true) + " MP";
    }
    let equityStr;
    if (processedData.equityBalance && processedData.equityBalance.gt(0)) {
      equityStr = formatAmount(processedData.equityBalance, 18, 2, true) + " EQUITY";
    }
    const amountStr = [equalAmountStr, esEqualAmountStr, mpAmountStr, equityStr].filter((s) => s).join(", ");
    earnMsg = (
      <div>
        You earn {nativeTokenSymbol} rewards with {formatAmount(totalRewardTokensAndEquity, 18, 2, true)} tokens.
        <br />
        Tokens: {amountStr}.
      </div>
    );
  }

  const { data: equityTokensWallet } = useSWR(
    [`StakeV2:equityTokensWallet:${active}`, chainId, equityTokenAddress, "balanceOf", account || PLACEHOLDER_ACCOUNT],
    {
      fetcher: fetcher(library, Token),
    }
  );

  const { data: equityTokensStaked } = useSWR(
    [`StakeV2:equityTokensStaked:${active}`, chainId, equityTokenAddress, "balanceOf", equityFarmAddress],
    {
      fetcher: fetcher(library, EquityFarm),
    }
  );

  return (
    <div className="FarmEquity page-layout default-container">
      <StakeModal
        isVisible={isStakeModalVisible}
        setIsVisible={setIsStakeModalVisible}
        chainId={chainId}
        title={stakeModalTitle}
        maxAmount={stakeModalMaxAmount}
        value={stakeValue}
        setValue={setStakeValue}
        active={active}
        account={account}
        library={library}
        stakingTokenSymbol={stakingTokenSymbol}
        stakingTokenAddress={stakingTokenAddress}
        equityFarmAddress={equityFarmAddress}
        stakeMethodName={stakeMethodName}
        setPendingTxns={setPendingTxns}
        nativeTokenSymbol={nativeTokenSymbol}
        wrappedTokenSymbol={wrappedTokenSymbol}
      />
      <UnstakeModal
        setPendingTxns={setPendingTxns}
        isVisible={isUnstakeModalVisible}
        setIsVisible={setIsUnstakeModalVisible}
        chainId={chainId}
        title={unstakeModalTitle}
        maxAmount={userStaked}
        reservedAmount={unstakeModalReservedAmount}
        value={unstakeValue}
        setValue={setUnstakeValue}
        library={library}
        unstakingTokenSymbol={unstakingTokenSymbol}
        equityFarmAddress={equityFarmAddress}
        unstakeMethodName={unstakeMethodName}
      />
      <div className="section-title-content mt-0">
        <div className="Page-title">Earn</div>
        <div className="Page-description">
          Farm with{" "}
          <a href="https://docs.equalizer.exchange/Equity/overview/" target="_blank" rel="noopener noreferrer">
            EQUITY
          </a>{" "}
          to earn rewards.
        </div>
        {earnMsg && <div className="Page-description">{earnMsg}</div>}
      </div>
      <div className="FarmEquity-content">
        <div className="FarmEquity-cards">
          <div className="App-card FarmEquity-gmx-card">
            <div className="App-card-title">EQUITY</div>
            <div className="App-card-divider"></div>
            <div className="App-card-content">
            <div className="App-card-row">
                <div className="label">Price</div>
                <div>${formatKeyAmount(processedData, "equityPrice", USD_DECIMALS, 3, true)}</div>
              </div>
              <div className="App-card-row">
                <div className="label">Wallet</div>
                <div>
                  {formatAmount(equityTokensWallet, EQUITY_DECIMALS, 2, true)} EQUITY ($
                  {(Number(formatAmount(equityTokensWallet, EQUITY_DECIMALS, 2, true)) * Number(formatKeyAmount(processedData, "equityPrice", USD_DECIMALS, 3, true))).toLocaleString("en-US")})
                  {/* {Number(formatKeyAmount(processedData, "equityBalanceUsd", USD_DECIMALS, 2, true)) * Number(formatKeyAmount(processedData, "equityPrice", USD_DECIMALS, 3, true))}) */}
                </div>
              </div>
              <div className="App-card-row">
                <div className="label">You Staked</div>
                <div>
                  {formatAmount(userStaked, EQUITY_DECIMALS, 2, true)} EQUITY ($
                    {(Number(formatAmount(userStaked, EQUITY_DECIMALS, 2, false)) * Number(formatKeyAmount(processedData, "equityPrice", USD_DECIMALS, 3, false))).toLocaleString("en-US")})
                </div>
              </div>
              <div className="App-card-divider"></div>
              <div className="App-card-row">
                <div className="label">APR</div>
                <div>
                  <Tooltip
                    handle={`${farmAprToken1 === undefined || farmAprToken2 === undefined ? 0 : (Number(formatAmount(farmAprToken1, 18, 2, false)) + Number(formatAmount(farmAprToken2, 18, 2, false))).toLocaleString("en-US")}%`}
                    position="right-bottom"
                    renderContent={() => {
                      return (
                        <>
                          {/* {(!farmAprToken1 ||
                            farmAprToken1.eq(0)) && ( */}
                            <div className="Tooltip-row">
                              <span className="label">EQUAL APR</span>
                              <span>{formatAmount(farmAprToken1, 18, 2, true)}%</span>
                            </div>
                          {/* )}
                          {(!farmAprToken2 ||
                            farmAprToken2.eq(0)) && ( */}
                            <div className="Tooltip-row">
                              <span className="label">veEQUAL APR</span>
                              <span>{formatAmount(farmAprToken2, 18, 2, true)}%</span>
                            </div>
                          {/* )} */}
                        </>
                      );
                    }}
                  />
                </div>
              </div>
              <div className="App-card-row">
                <div className="label">Rewards</div>
                <div>
                  <Tooltip
                    handle={`${earnedToken1 === undefined || earnedToken2 === undefined ? 0 : (Number(formatAmount(earnedToken1, 18, 6, false)) + Number(formatAmount(earnedToken2, 18, 6, false))).toLocaleString("en-US")}`}
                    position="right-bottom"
                    renderContent={() => {
                      return (
                        <>
                          <div className="Tooltip-row">
                            <span className="label">
                              EQUAL
                            </span>
                            <span>
                              {formatAmount(earnedToken1, 18, 6, true)} ($
                              {(Number(formatAmount(earnedToken1, 18, 6, false)) * Number(formatAmount(equalPrice, 30, 6, false))).toLocaleString("en-US")})
                            </span>
                          </div>
                          <div className="Tooltip-row">
                            <span className="label">
                              veEQUAL
                            </span>
                            <span>
                              {formatAmount(earnedToken2, 18, 4, true)} ($
                                {(Number(formatAmount(earnedToken2, 18, 6, false)) * Number(formatAmount(equalPrice, 30, 6, false))).toLocaleString("en-US")})
                            </span>
                          </div>
                        </>
                      );
                    }}
                  />
                </div>
              </div>
              <div className="App-card-divider"></div>
              <div className="App-card-row">
                <div className="label">Total Staked</div>
                <div>
                  {!totalEquityTokenStakedFarm && "..."}
                  {totalEquityTokenStakedFarm && (
                    <Tooltip
                      position="right-bottom"
                      className="nowrap"
                      handle={
                        formatAmount(totalEquityTokenStakedFarm, 18, 2, true) +
                        " EQUITY" +
                        ` ($${formatAmount(totalEquityTokenStakedFarmDollars, 18, 2, true)})`
                      }
                      renderContent={() => (
                        <>
                          {formatAmount(totalEquityTokenStakedFarm, 18, 2, true)} EQUITY
                        </>
                      )}
                    />
                  )}
                </div>
              </div>
              <div className="App-card-divider"></div>
              <div className="App-card-options">
                {active && (
                  <button disabled={!userCanStake || !lastAddedLiq} className="App-button-option App-card-option" onClick={userCanStake ? () => showStakeEquityTokenModal() : null}>
                    Stake {lastAddedLiq ? userCanStake ? null : "(in cooldown)" : null}
                  </button>
                )}
                {active && (
                  <button className="App-button-option App-card-option" onClick={() => showUnstakeEquityTokenModal()}>
                    Unstake
                  </button>
                )}
                {active && (
                  <ClaimButton
                    chainId={chainId}
                    library={library}
                    equityFarmAddress={equityFarmAddress}
                    setPendingTxns={setPendingTxns}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <i>Note: There is a minimum 15 minutes holding time after adding liquidity.</i>
      <Footer />
    </div>
  );
}